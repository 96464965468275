
import { ApprovalOptBody, approvalService } from "@/service";
import { RouterName } from "@/router";
import { useRoute, useRouter } from "vue-router";
import { defineComponent, reactive, ref, toRefs, computed, toRef } from "vue";
import { Toast, Dialog } from "vant";
import { UserInfo } from "@/components/choose-person/Index.vue";
import { SubFormItemGroup } from "@/components/check/SubFormItemGroup.vue";
import rulesRegulations from "@/views/rules-regulations/index.vue";
import { useValidator } from "@/composables";
import { Process } from "./Process.vue";
import moment from "moment";

type ButtonInfo = { type: number; name: string };
type Added = {
  create_date: string;
  duty_name: string;
  from_name: string;
  head_pic: string;
  id: string;
  msg: string;
  name: string;
  reason: string;
};
type NextNodeInfo = {
  approval_way_type: number;
  approval_way_type_name: string;
  next_node_id: string;
  next_node_name: string;
  power_approval_type: number;
  power_approval_type_name: string;
  user_ids: string;
  user_info: [];
  user_names: string;
  user_num: number;
};
type ReturnableNodeInfo = {
  id: string;
  name: string;
};
const getDefaultForm = () => ({
  return_info: {
    log: "",
    reason: "",
    user_info: {} as UserInfo
  },
  event_detail: {
    event_code: "",
    id: ""
  },
  name: "",
  code: "",
  qrcode: "",
  state_name: "",
  create_date: "",
  from_name: "",
  is_adding: 0,
  added_arr: [] as Added[],
  approval_json: [],
  approval_process_on: {} as Process,
  opt_lists: [] as ButtonInfo[],
  approval_token: "",
  id: "",
  user_name: "",
  next_node_info: {} as NextNodeInfo,
  operating_conditions_json: {} as Approval.OperatingConditionsJson,
  approval_temp_id: "",
  returnable_node: [] as ReturnableNodeInfo[],
  insure_code: "",
  insure_url: "",
  pass_url: ""
});
type ApprovalInfo = ReturnType<typeof getDefaultForm>;
export default defineComponent({
  name: "ApprovalDetail",
  components: {
    rulesRegulations
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    // 查看规章制度
    const isShowrules = reactive({
      isRules: false
    });
    const lookRules = () => {
      isShowrules.isRules = !isShowrules.isRules;
    };
    // 查看二维码
    const aboutQrcode = reactive({
      isQrcode: false
    });
    const lookQrcode = () => {
      aboutQrcode.isQrcode = !aboutQrcode.isQrcode;
    };
    const closeQrcode = () => {
      aboutQrcode.isQrcode = false;
    };

    // 表单组件ref
    const subFormItemGroupRef = ref<SubFormItemGroup | null>(null);
    // 获取详情
    const approvalInfo = reactive<ApprovalInfo>(getDefaultForm());
    const showLock = ref(false);
    const getApprovalInfo = async () => {
      try {
        const { data } = await approvalService.approvalInfo(route.params.id as string);

        Object.assign(approvalInfo, data.data[0]);
        isShowrules.isRules = Number(approvalInfo.operating_conditions_json?.is_checked) == 1;
      } catch (error) {
        if (error.state === "014") {
          showLock.value = true;
        }
        Object.assign(approvalInfo, getDefaultForm());
      }
    };
    getApprovalInfo();
    // 查看电子保单
    const lookInsure = () => {
      // eslint-disable-next-line no-undef
      wx.miniProgram.navigateTo({
        url: `/pages/download/main?url=${approvalInfo.insure_url}&type=pdf&action=0`
      });
    };
    // 选择人员
    const choosePerson = reactive({
      show: false,
      onConfirm(value: Dictionary<UserInfo[]>, ids: string) {}
    });
    const choosePerson2 = reactive({
      show: false,
      onConfirm(value: Dictionary<UserInfo[]>, ids: string) {}
    });
    const useChoosePerson = async (choose: typeof choosePerson | typeof choosePerson2) => {
      choose.show = true;
      const user_ids = await new Promise<string>(resolve => {
        choose.onConfirm = (value, ids) => {
          resolve(ids);
        };
      });
      return user_ids;
    };
    // 当前页面是否加签申请人
    const isPetitioner = computed(() => {
      return approvalInfo.opt_lists.some(button => button.type == 4);
    });
    // 显示页面最顶的加签申请或回复详情的弹窗
    const dialogShow = ref(false);
    // 显示和使用填写原因的的弹窗
    const reasonDialog = reactive({
      show: false,
      title: "",
      reason: "",
      onConfirm() {},
      beforeClose(): boolean {
        return true;
      }
    });
    const useReasonDialog = async (title: string, reasonRequire = false) => {
      reasonDialog.title = title;
      reasonDialog.show = true;

      await new Promise<boolean>(resolve => {
        reasonDialog.onConfirm = () => {
          if (reasonRequire && !reasonDialog.reason) return;
          resolve(true);
        };
        if (reasonRequire) {
          reasonDialog.beforeClose = (action?: string) => {
            console.log(action, reasonDialog.reason);
            if (action === "confirm" && !reasonDialog.reason) {
              Toast.fail("请输入审批意见");
              return false;
            }
            return true;
          };
        }
      });
      const reason = reasonDialog.reason;
      reasonDialog.reason = "";
      return reason;
    };
    // 显示和使用处理加签申请原因的弹窗
    const replyApplySign = reactive({
      show: false,
      result: "1",
      reason: "",
      onConfirm() {}
    });
    const useReplyApplySignDialog = async () => {
      replyApplySign.show = true;
      await new Promise<boolean>(resolve => {
        replyApplySign.onConfirm = () => {
          resolve(true);
        };
      });
      const result = replyApplySign.result;
      const reason = replyApplySign.reason;
      replyApplySign.reason = "";
      replyApplySign.result = "1";
      return [result, reason];
    };
    // 可退回的弹出框
    const returnableNodePicker = reactive({
      show: false,
      onConfirm(value: ReturnableNodeInfo) {},
      onCancel() {
        returnableNodePicker.show = false;
      }
    });
    const useReturnableNodePicker = async () => {
      returnableNodePicker.show = true;
      return new Promise<ReturnableNodeInfo>(resolve => {
        returnableNodePicker.onConfirm = value => {
          returnableNodePicker.show = false;
          resolve(value);
        };
      });
    };
    //提交用户输入的信息
    const submit = async () => {
      const approval_json_result = await new Promise((resolve, reject) => {
        subFormItemGroupRef.value?.submit(value => {
          const find = useValidator(approvalInfo.approval_json, value);
          if (find) {
            reject({
              message: find.message
            });
            return;
          }
          resolve(value);
        });
      });
      const { data } = await approvalService.approvalStartDo({
        approval_temp_id: approvalInfo.approval_temp_id,
        approval_json_result: JSON.stringify(approval_json_result),
        id: approvalInfo.id,
        approval_token: approvalInfo.approval_token
      });
      return data.data[0];
    };
    // 选择下级审批人
    const selectApproveInfo = reactive({
      isShow: false,
      info: {},
      onSubmit(ids: string) {}
    });
    // 出入证
    const toPassUrl = () => {
      window.location.href = approvalInfo.pass_url;
    };
    // 点击下面的按钮
    const buttonClick = async (button: ButtonInfo) => {
      try {
        const formData: ApprovalOptBody = {
          approval_token: approvalInfo.approval_token,
          approval_id: approvalInfo.id,
          type: button.type
        };
        switch (button.type) {
          // 确定按钮
          case 1: {
            // 先去重新提交用户填写的表单
            const data = await submit();
            const { next_node_info } = data;
            formData.next_node_id = next_node_info.next_node_id;
            if (formData.next_node_id != "2") {
              formData.user_ids = next_node_info.user_ids || (await useChoosePerson(choosePerson));
            }
            formData.reason = await useReasonDialog("同意申请");
            break;
          }
          // 拒绝
          case 2: {
            formData.reason = await useReasonDialog("拒绝申请", true);
            break;
          }
          // 退回节点
          case 3: {
            const value = await useReturnableNodePicker();
            formData.target_node_id = value.id;
            formData.reason = await useReasonDialog(`退回至 ${value.name}`, true);
            break;
          }
          // 申请加签
          case 4: {
            formData.user_ids = await useChoosePerson(choosePerson);
            formData.reason = await useReasonDialog("申请加签");
            break;
          }
          // 撤回审批申请
          case 5:
            await Dialog.confirm({
              title: "提示",
              message: "确定撤回该申请？"
            });
            break;
          // 重新发起
          case 8: {
            const data = await submit();
            const { id, next_node_info } = data;
            selectApproveInfo.info = next_node_info;
            selectApproveInfo.isShow = true;
            const ids = await new Promise<string>(resolve => {
              selectApproveInfo.onSubmit = ids => {
                resolve(ids);
              };
            });
            await approvalService.approvalStartSure(id, ids);
            getApprovalInfo();
            return;
          }
          // 删除审批申请
          case 13:
            await Dialog.confirm({
              title: "提示",
              message: "确定删除该申请？"
            });
            break;
          // 处理加签申请
          case 14: {
            const [result, reason] = await useReplyApplySignDialog();
            formData.result = result;
            formData.reason = reason;
            break;
          }
          case 21: {
            const ids = await useChoosePerson(choosePerson2);
            await Dialog.confirm({
              title: "提示",
              message: `确定将审批抄送给这${ids.split(",").length}人吗？`
            });
            formData.user_ids = ids;
            break;
          }
        }
        await approvalService.approvalOpt(formData);
        switch (button.type) {
          case 1:
            Toast.success("已审批");
            break;
          case 2:
            Toast.success("已审批");
            break;
          case 3:
            Toast.success("已退回");
            break;
          case 4:
            Toast.success("已加签");
            break;
          case 5:
            Toast.success("已撤回");
            break;
          case 7:
            Toast.success("已催办");
            break;
          case 12:
            Toast.success("申请已撤回");
            break;
          case 13:
            Toast.success("单据已删除");
            router.push({
              name: RouterName.MY_APPROVAL
            });
            return;
          case 14:
            Toast.success("已提交");
            break;
          case 21:
            Toast.success("已抄送");
            break;
        }
        getApprovalInfo();
      } catch (e) {
        if (!e.message) return;
        Toast.fail(e.message);
      }
    };
    // 点击更多的三个点的按钮
    const showPopover = ref(false);
    const buttonMoreList = computed((): { text: string }[] => {
      return approvalInfo.opt_lists
        .filter((item, index) => index > 1)
        .map(item => ({
          text: item.name,
          ...item
        }));
    });
    const popoverSelect = (action: ButtonInfo) => {
      buttonClick(action);
    };

    return {
      moment,
      lookInsure,
      lookRules,
      aboutQrcode,
      lookQrcode,
      closeQrcode,
      isShowrules,
      selectApproveInfo,
      returnableNodePicker,
      subFormItemGroupRef,
      replyApplySign,
      reasonDialog,
      isPetitioner,
      dialogShow,
      buttonClick,
      showPopover,
      popoverSelect,
      buttonMoreList,
      choosePerson,
      choosePerson2,
      toPassUrl,
      showLock,
      ...toRefs(approvalInfo)
    };
  }
});
